<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="SalesReports">
      <template v-slot:right-end>
        <vs-button @click="viewAllReportData()"
                   class="mt-3 py-4 px-3 ml-4" color="primary">
          {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
        </vs-button>
      </template>
    </breadcrumb-base>


    <transition name="fade">
      <div class="vx-row">
        <div class="vx-col md:w-1/5 4">
          <vx-card>
            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <!--            From TO Date Filters -->
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')" v-model="endDate"
                        :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
          </vx-card>
        </div>
        <div class="vs-col md:w-4/5">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="sales.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
                <!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="sales.length > 0 && !errorFetching" ref="table"
                             :max-items="20" search
                             :data="sales"
          >

            <template slot="thead">


              <vs-th>
                <div class="">
                  <p>{{ $t('SoldDate') }}</p>
                </div>
              </vs-th>
              <vs-th>{{ $t('Products') }}</vs-th>
              <vs-th class="">{{ $t('TotalPrice') }}</vs-th>
              <vs-th>{{ $t('User') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div class="">
                    <p class="product-name font-medium truncate">{{ tr.created | dateFormat }}</p>
                  </div>
                </vs-td>
                <vs-td>
                  <vx-tooltip>
                    <template slot="tip">
                      <div class="vs-col">
                        <p v-for="(product, index) of tr.product_data">
                          <strong>{{ product.name }} x {{ product.quantity }} - <i>{{ product.price }}</i></strong>
                        </p>
                      </div>
                    </template>

                    <div class="vs-col">
                      <p class="truncate" v-if="tr.product_data && tr.product_data.length > 0">
                        {{ tr.product_data[0].name }}
                        <strong v-if="tr.product_data.length > 1">(+ {{ tr.product_data.length - 1 }})</strong>
                      </p>
                    </div>
                  </vx-tooltip>
                </vs-td>

                <vs-td>
                  <p class="product-name">{{ tr.total_price | germanNumberFormat4 }}</p>
                </vs-td>

                <vs-td>
                  <vx-tooltip>
                    <template slot="tip">
                      <div class="vs-col">
                        <p><strong>{{ $t('CustomerID') }}: {{ tr.customer_id }}</strong></p>
                      </div>
                    </template>

                    <div class="vs-col">
                      <p class="truncate">
                        {{ tr.organisation_name | capitalize }}
                        <strong>({{ tr.customer_id }})</strong>
                      </p>
                      <p class="truncate" style="max-width: 150px;">
                        {{ tr.organisation_first_name || tr.first_name }}
                        {{ tr.organisation_last_name || tr.last_name }}
                      </p>
                      <p style="font-size: 11px; color: black">
                        {{ tr.organisation_address }}<span v-if="tr.organisation_house_no"> {{ tr.organisation_house_no }}</span>
                      </p>
                      <p style="font-size: 11px; color: black">
                        {{ tr.organisation_postal }} {{ tr.organisation_city }}
                      </p>
                    </div>
                  </vx-tooltip>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage"
                                   v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from '@/layouts/components/custom/VxModal'
import ReceiptPrint from '@/views/components/ReceiptPrint'
import ReclaimPins from "@/views/components/ReclaimPins";

export default {
  name: 'HardwareSalesReport',
  components: {
    ReclaimPins,
    ReceiptPrint,
    Datepicker,
    VxModal,
  },
  data() {
    return {
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Hardware Sales Report', i18n: 'HardwareSalesReport', active: true},
      ],
      filterSelectData: {},
      startDate: null,
      endDate: null,
      viewAll: false,
    }
  },
  computed: {
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    vPage() {
      return this.$store.state.hardwareSeller.vPage
    },
    vTotalPages() {
      return this.$store.state.hardwareSeller.vTotalPages
    },
    sales() {
      return this.$store.state.hardwareSeller.orders
    },
    salesFilters() {
      return this.$store.state.reports.typeOfFilters || {}
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.sales.length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {

    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    onFilterChanged() {
      this.filterChanged()
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.startDate = null
      this.endDate = null
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
    getFilters() {
      const filter = {}
      Object.keys(this.filterSelectData).forEach((key) => {
        if (this.filterSelectData[key] != null) {
          filter[key] = this.filterSelectData[key].replace(key, '')
        }
      })
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }
      if (this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }
      filter['limit'] = 20;
      return filter
    },
    filterChanged() {
      this.fetchSales('0', this.getFilters())
    },
    listPageChanged(item) {
      this.fetchSales(this.cPage - 1, this.getFilters())
    },
    fetchSales(page = '0', filter = {}) {
      this.$vs.loading()
      return this.$store.dispatch('hardwareSeller/fetchHardwareOrdersList', {page, filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.endDate = new Date()
    this.startDate = new Date()
    this.fetchSales('0', this.getFilters())
  },
}
</script>

<style lang="scss">

#sales-reports-view {
  .vs-select--label {
    padding: 0;
  }

  .header-table {
    width: auto;

    th {
      font-weight: normal;
      font-size: 14px;
      min-width: 200px;
    }

    td {
      font-weight: bold;
      font-size: 24px;
      color: rgba(var(--vs-primary), 1);
    }
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>

